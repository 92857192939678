import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, Container } from '@material-ui/core';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../javascript/config/theme';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Copyright from '../components/Copyright.js'
import Banners from '../single-page/pages/Banners.js'
import SignIn from './SignIn.js'
import SignUp from './SignUp.js'
import Termos from '../single-page/pages/Termos.js'
import Faq from '../single-page/pages/Faq.js'
import ResetPassword from './ResetPassword.js'
import RequestPasswordReset from './RequestPasswordReset.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../public/styles/main.scss";
import logo from 'images/marca.svg';
import Footer from '../components/Footer.js'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '800px',
    '@media(max-width: 1440px)': {
      minHeight: '600px',
    },
  },
  banner: {
    padding: '0px',
    textAlign: '-webkit-center'
  },
  '& .MuiAppBar-positionFixed': {
    position: 'relative'
  },
  footer: {
    width: '100%',
    position: 'relative',
    bottom: 0,
    backgroundColor: '#091250',
    marginTop: '64px',
    '@media(max-width: 1280px)': {
      position: 'relative'
    },
    '@media(max-width: 600px)': {
      marginTop: '64px',
    }
  }
}));

export default function Public({ flash, global_schema: globalSchema = { current_customer: {} } }) {
  const classes = useStyles();
  
  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer autoClose={8000} />
        <CssBaseline />
        <AppBar style={{ position: 'relative' }}>
          <Toolbar style={{ justifyContent: 'center', color: '#fff' }}>
            <Link to="/">
              <img src={logo} alt="" title={"icone"} width="180" height="50" />
            </Link>
          </Toolbar>
        </AppBar>
        <Container className={classes.banner} component="main" maxWidth="xl">
          <Route exact path={["/", "/public", "/public/sign_in", "/public/sign_up", "/public/reset_password", "/public/request_password_reset"]}>
            <Banners banners={globalSchema.login_banners} />
          </Route>
          <Route exact path="/public/termos">
            <Termos content={globalSchema.termos} />
          </Route>
          <Route exact path="/public/faq">
            <Faq content={globalSchema.faq} />
          </Route>
        </Container>
        <Container className={classes.paper} maxWidth="xs">
          <Route exact path={["/public/sign_in"]}>
            <SignIn />
          </Route>
          <Route exact path={["/", "/public", "/public/sign_up"]}>
            <SignUp />
          </Route>
          <Route exact path="/public/reset_password">
            <ResetPassword />
          </Route>
          <Route exact path="/public/request_password_reset">
            <RequestPasswordReset />
          </Route>
        </Container>
        {navigator.userAgent != "app-ios" ? (
          <Box className={classes.footer}>
            <Footer />
          </Box>) : ("")
        }
      </Router>
    </ThemeProvider>
  );
}
