import React, { useState } from 'react';
import { Button, TextField, Typography, Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import AuthorizationClient from '../single-page/api/AuthorizationClient'
// import FileField from '../components/FileField.js'
import LoadingButton from '../components/LoadingButton.js'

const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'Poppins-SemiBold',
    alignSelf: 'start'
  },
  newAccountFooter: {
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #6600CB',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#091250',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  submitSignUp: {
    margin: theme.spacing(0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  link: {
    color: '#D2B5FF',
    textDecoration: 'none'
  },
  link2: {
    color: '#fff',
    textDecoration: 'none'
  }
}));

export default function SignUp() {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  
  function sign_up(e) {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();

    AuthorizationClient.signUp(user)
      .then(function(){
        toast.info('Para dar palpites, em algumas partidas, a confirmação de e-mail se faz necessária.');
        window.location.href = '/site';
      })
      .catch(function(response){
        if (response.status == 422){
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <div className={classes.paper}>
      <Typography className={classes.newAccount} component="h1" variant="h5">
        Crie sua conta
      </Typography>
      <Typography className={classes.newAccountFooter}>
        Crie sua conta para participar
      </Typography>
      <form className={classes.form} onSubmit={sign_up} noValidate>
        {[
          { name: 'email', label: 'Email', type: 'email', required: true },
          { name: 'password', label: 'Senha', type: 'password', required: true },
          { name: 'password_confirmation', label: 'Confirmação da senha', type: 'password', required: true }
        ].map((field) =>
          <TextField
            required={field.required}
            key={field.name}
            id={field.name}
            name={field.name}
            label={field.label}
            fullWidth={true}
            autoComplete={field.name}
            autoFocus={field.autoFocus}
            value={user[field.name]}
            error={errors[field.name] && true}
            type={field.type}
            helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
            onChange={(e) => setUser({...user, [field.name]: e.target.value})}
          />
          )}
        <Typography style={{ fontSize: '14px', paddingTop: '10px' }}>
          Ao clicar, você concorda com <Link className={classes.link}>Termos e condições</Link> e <Link className={classes.link}>Política de privacidade</Link>
        </Typography>
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          CADASTRAR
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submitSignUp}
        >
          <Link className={classes.link2} to="/public/sign_in">
            LOGIN
          </Link>
        </LoadingButton>
      </form>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid style={{ padding: '20px 20px 10px 20px' }} item>
          <Link className={classes.link} to="/public/request_password_reset" variant="body2">
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
